import { Component, Input, OnInit } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { SitesService } from 'src/app/services/sites.service';
import { VehicleMakesService } from 'src/app/services/vehicle-makes.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.less']
})
export class VehiclesComponent implements OnInit {

  List: Array<any> = [];
  currentSite: any;
  currency: any;
  constructor(private apiService: GearsApiService, private siteService: SitesService, private makesservice: VehicleMakesService) { }
  @Input() public type:string = '';
  @Input() public vehicletype: string = '';
  vehicletypelist : any;
  buyUrl: string = ''; 
  urlsan(url: any) {
    return url.replaceAll('+', '').replaceAll('/', '').replaceAll('\\', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '_');
  }
  ngOnInit(): void {
    this.siteService.get().then((x: any) => {
      this.currentSite = x;
      this.currency = this.currentSite.CurrencySymbol;
      if (this.vehicletype != '' && this.type.toLowerCase() != 'specials'){
        this.apiService.get('VehicleTypes',{
          PageSize: 99,
          PageNumber: 1,
          OrderBy: '',
          Code: this.vehicletype
        })
        .then((x: any) => {
          this.vehicletypelist = x.body[0] ?? null;
          this.buyUrl = this.type != 'all'
          ? '/buy?type='+(this.type||'').toLowerCase()+'&bodytype='+this.vehicletypelist.VehicleTypeID_PK
          : '/buy?bodytype='+this.vehicletypelist.VehicleTypeID_PK;
        })
      }
      else if(this.type.toLowerCase() == 'specials'){
        if(this.vehicletype == '' ||this.vehicletype.toLowerCase() == 'all' ||this.vehicletype.toLowerCase() == undefined ||this.vehicletype.toLowerCase() == null){
          this.buyUrl = '/buy?type=specials';
        }
        else{
        this.apiService.get('SpecialCategories', {
        
          PageSize: 1,
          PageNumber: 1,
          SiteID_FK: this.currentSite.SiteID_PK,
          Code: this.vehicletype
          
          //OrderBy: 'DateInStock.DESC'
        }).then((x: any) => {
          this.buyUrl = '/buy?type=specials&specialType='+ x.body[0].SpecialCategoryID_PK
          
        });
      }
      }
      else{
        this.buyUrl = this.type != 'all' 
          ? '/buy?type='+(this.type||'').toLowerCase()
          : '/buy';
      }
      if(!this.type || this.type == '' || this.type.toLowerCase() == 'all'){
      this.apiService.get('VehicleStock', {

        PageSize: 6,
        PageNumber: 1,
        SiteID_FK: this.currentSite.SiteID_PK,
        VehicleTypeCode: this.vehicletype,
        OrderBy: 'DateInStock.DESC'
      }).then((x: any) => {
        //x.array.forEach((element:any) => {element.type = 'new';});
        this.List = x.body
      });
    } else if(this.type.toLowerCase() == 'new'){
      this.apiService.get('VehicleStock', {
        VehicleStockTypeID_FK: 1,
        PageSize: 6,
        PageNumber: 1,
        SiteID_FK: this.currentSite.SiteID_PK,
        VehicleTypeCode: this.vehicletype,
        OrderBy: 'DateInStock.DESC'
      }).then((x: any) => {
        //x.array.forEach((element:any) => {element.type = 'new';});
        this.List = x.body
      });
    }else if(this.type.toLowerCase() == 'demo'){
      this.apiService.get('VehicleStock', {
        VehicleStockTypeID_FK: 3,
        PageSize: 6,
        PageNumber: 1,
        SiteID_FK: this.currentSite.SiteID_PK,
        VehicleTypeCode: this.vehicletype,
        OrderBy: 'DateInStock.DESC'
      }).then((x: any) => {
        //x.array.forEach((element:any) => {element.type = 'new';});
        this.List = x.body
      });
    }else if(this.type.toLowerCase() == 'used'){
      this.apiService.get('VehicleStock', {
        VehicleStockTypeID_FK: 2,
        PageSize: 6,
        PageNumber: 1,
        SiteID_FK: this.currentSite.SiteID_PK,
        VehicleTypeCode: this.vehicletype,
        OrderBy: 'DateInStock.DESC'
      }).then((x: any) => {
        //x.array.forEach((element:any) => {element.type = 'new';});
        this.List = x.body
      });
    }else if(this.type.toLowerCase() == 'specials'){

      if(this.vehicletype && this.vehicletype != '' && this.vehicletype.toLowerCase() != 'all'){
      this.apiService.get('SpecialCategories', {
        
        PageSize: 1,
        PageNumber: 1,
        SiteID_FK: this.currentSite.SiteID_PK,
        Code: this.vehicletype
        
        //OrderBy: 'DateInStock.DESC'
      }).then((y: any) => {
        this.apiService.get('Specials', {
          PageSize: 6,
          PageNumber: 1,
          SiteID_FK: this.currentSite.SiteID_PK,
          SpecialCategoryID_FK: y.body[0].SpecialCategoryID_PK
          //OrderBy: 'DateInStock.DESC'
        }).then((x: any) => {
          //x.array.forEach((element:any) => {element.type = 'new';});
          this.List = x.body
        });
      });
    }
    else{
      this.apiService.get('Specials', {
        PageSize: 6,
        PageNumber: 1,
        SiteID_FK: this.currentSite.SiteID_PK
        //OrderBy: 'DateInStock.DESC'
      }).then((x: any) => {
        //x.array.forEach((element:any) => {element.type = 'new';});
        this.List = x.body
      });
    }
  }
    });

  }

  imgadd(url: string, iswebp: boolean = false) {

    if (iswebp) {
      if (!url.includes('http')) {
        return (environment.storageurl + url).replace('.png','.webp').replace('.jpg','.webp').replace('.jpeg','.webp').replace('.svg','.webp');
      }
      else {
        return url.replace('.png','.webp').replace('.jpg','.webp').replace('.jpeg','.webp').replace('.svg','.webp');
      }
    }
    else {

      if (!url.includes('http')) {
        return environment.storageurl + url;
      }
      else {
        return url;
      }
    }
  }
  VehicleMakeTypeExcludeVAT:any = [];
  Makes:any;
  getVATStatus(){
    this.makesservice.getStock().then((x: any) => {
      this.Makes = x;
      // if (this.Site.layoutFeatures.includes('vatexclusivelabel')){
        this.apiService.get('SiteBrandVehicleTypes',{
          OrderBy:'Name.ASC',
          PageNumber:1,
          PageSize:9999,
          SiteID_FK:this.currentSite.SiteID_PK,
          Active:true,
          Deleted:false
        }).then((x:any)=>{
          let SBVT = x.body;
          SBVT = SBVT.filter((obj:any)=> obj.VatInclusive == false);
          let VehicleMakeType:any = [];
          const arrPost = []
          for (let i = 0; i<SBVT.length; i++) {
            VehicleMakeType.push(SBVT[i]);
            arrPost.push(this.apiService.get('BrandVehicleMakes',
              {
                BrandID_FK:SBVT[i].BrandID_FK,
                Active:true,
                Deleted:false,
                PageNumber:1,
                PageSize:9999,
                OrderBy: ''
              }
            ));
          }
          Promise.all(arrPost).then((vals:any)=>{
            for (let i = 0; i<vals.length; i++) {
              const _make = this.Makes.find((m:any)=> m.VehicleMakeID_PK = vals[i].body[0].VehicleMakeID_FK)
              VehicleMakeType[i].VehicleMake = _make.Name;
            }
            this.VehicleMakeTypeExcludeVAT= VehicleMakeType;
          })
        });
      // }
    });
  }

  VatInclusive(vehicleType:any, vehicleMake:any){
    return this.VehicleMakeTypeExcludeVAT.find((x:any)=> x.VehicleTypeName == vehicleType && x.VehicleMake == vehicleMake)?.VatInclusive??true;
  }
}
