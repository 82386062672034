<div *ngIf="List.length > 0" class="col-12">
    <div class="row">
        <div class="col-6">
            <p *ngIf="!vehicletype && this.type.toLowerCase() !== 'specials'">Latest {{type}} Stock</p>
            <p *ngIf="vehicletype && this.type.toLowerCase() !== 'specials'">{{vehicletypelist.Name}} Vehicles</p>
            <p *ngIf="this.type.toLowerCase() == 'specials'">Latest {{vehicletype?vehicletype+" ":''}} Specials</p>
        </div>
        <div class="col-6">
            <a *ngIf="type != 'new'"  href="{{this.buyUrl}}">
                <p class="more">View All</p>
            </a>
        </div>
    </div>
    <div *ngIf="this.type.toLowerCase() == 'specials'" class="row list">


        <div *ngFor="let v of List;" class="col-12 col-lg-2 col-md-4">
            <div class="card"><a href="/buy/special/{{v.SpecialID_PK}}/{{urlsan(v.Caption)}}">
                    <div one class="card-img-top" *ngIf="v.ImageFilename !== null && v.ImageFilename">
                        <img class="w-100" src="{{imgadd(v.ImageFilename,true)}}" onerror="this.style.display='none'; this.nextElementSibling.style.display='inline-block';" alt="" />
                        <img class="w-100" loading="lazy" src="{{imgadd(v.ImageFilename)}}" alt="" onerror="this.style.display='none'; this.nextElementSibling.style.display='inline-block';" style="display: none;"/>
                    </div>
                    <img loading="lazy" class="card-img-top" *ngIf="v.ImageFilename == null || !v.ImageFilename" src="{{currentSite.vehicleImages.Value}}" alt=""/>
                    <div class="card-body">
                        <h4>{{v.Caption}}</h4>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <div *ngIf="this.type.toLowerCase() !== 'specials'" class="row list">


        <div *ngFor="let v of List;" class="col-12 col-md-4 col-lg-2">
            <div class="card  vehicle"><a href="/buy/stock/{{v.VehicleStockID_PK}}/{{urlsan(v.WebFriendlyName)}}">
                    <div one class="card-img-top" *ngIf="v.Images !== null && v.Images[0]">
                        <img class="w-100" src="{{imgadd(v.Images[0],true)}}" onerror="this.style.display='none'; this.nextElementSibling.style.display='inline-block';" alt="" />
                        <img class="w-100" loading="lazy" src="{{imgadd(v.Images[0])}}" alt="" onerror="this.style.display='none'; this.nextElementSibling.style.display='inline-block';" style="display: none;"/>
                        <img *ngIf="v.InstavidSpinID" class="instavidimg" src="assets/360img.png" alt=""/>
                    </div>
                    <img loading="lazy" class="card-img-top" *ngIf="v.Images == null || !v.Images[0]" src="{{currentSite.vehicleImages.Value}}" alt=""/>
                    <div class="card-body">
                        <h4>{{v.ModelYear}} {{v.WebFriendlyName}}</h4>
                        <h3>{{this.currency}}{{v.RetailPriceIncl | number:'1.0-0'}} <small style="white-space: nowrap;" *ngIf="VehicleMakeTypeExcludeVAT.length > 0 && !VatInclusive(v.VehicleType, v.VehicleMake)"> Ex VAT</small></h3>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
